import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getListings } from '../services/api';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Pagination,
  TextField,
  IconButton,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Breadcrumbs,
  Link,
  Paper,
  ListItemButton,
  ListItemIcon,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Badge
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Header from './Header';
import ProductDetail from './ProductDetail';
import CartDrawer from './CartDrawer';
import { getCart } from '../services/cartService';

const Listings = () => {
  const [page, setPage] = useState(9);
  const [filters, setFilters] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(9);
  const [goToPage, setGoToPage] = useState('9');
  const [isCartOpen, setIsCartOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, isLoading, error } = useQuery(
    ['listings', page, filters, currentPath],
    () => getListings({ 
      page, 
      ...filters,
      path: currentPath.length > 0 ? currentPath[currentPath.length - 1].full_slug : undefined
    }),
    { keepPreviousData: true }
  );

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
    setPage(1);
  };

  const handleCategoryClick = (category) => {
    // Si la categoría ya está en el path, cortamos hasta ese punto
    const existingIndex = currentPath.findIndex(c => c.id === category.id);
    if (existingIndex !== -1) {
      setCurrentPath(currentPath.slice(0, existingIndex + 1));
    } else {
      // Si no está en el path, la agregamos al final
      setCurrentPath([...currentPath, category]);
    }
    setPage(1);
  };

  const handleNavigate = (url) => {
    // Remove the domain if present
    const path = url.replace('https://pontupedido.com', '');
    setCurrentPath(path);
  };

  const handleProductClick = (productId) => {
    console.log('Product clicked:', productId);
    setSelectedProductId(productId);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setGoToPage(value.toString());
    setPage(value);
  };

  const handleGoToPageChange = (event) => {
    const value = event.target.value;
    // Solo permitir números
    if (/^\d*$/.test(value)) {
      setGoToPage(value);
    }
  };

  const handleGoToPageKeyPress = (event) => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(goToPage);
      if (pageNumber >= 1 && pageNumber <= data?.pagination?.total_pages) {
        setCurrentPage(pageNumber);
        setPage(pageNumber);
      } else {
        // Si el número está fuera de rango, resetear al valor actual
        setGoToPage(currentPage.toString());
      }
    }
  };

  const renderBreadcrumbs = () => {
    if (currentPath.length === 0) return null;

    return (
      <Paper elevation={0} sx={{ p: 2, mb: 2, backgroundColor: 'transparent' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link
            component="button"
            variant="body1"
            onClick={() => setCurrentPath([])}
            color="inherit"
            sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
          >
            Inicio
          </Link>
          {currentPath.map((category, index) => (
            <Link
              key={category.id}
              component="button"
              variant="body1"
              onClick={() => setCurrentPath(currentPath.slice(0, index + 1))}
              color={index === currentPath.length - 1 ? 'primary' : 'inherit'}
              sx={{ 
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                fontWeight: index === currentPath.length - 1 ? 'bold' : 'normal'
              }}
            >
              {category.name}
            </Link>
          ))}
        </Breadcrumbs>
      </Paper>
    );
  };

  const renderFilters = () => {
    if (!data?.sidebar) return null;

    return (
      <List>
        {data.sidebar.map((filterGroup) => {
          if (filterGroup.type === 'subcates') {
            return (
              <div key={filterGroup.field}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
                  {filterGroup.title}
                </Typography>
                <List component="nav" dense>
                  {filterGroup.values.map((category) => (
                    <ListItemButton
                      key={category.id}
                      onClick={() => handleCategoryClick(category)}
                      sx={{
                        pl: 1,
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        }
                      }}
                    >
                      <ListItemText primary={category.name} />
                      <ListItemIcon sx={{ minWidth: 'auto' }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              </div>
            );
          }

          return (
            <div key={filterGroup.field}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
                {filterGroup.title}
              </Typography>
              {filterGroup.values.map((value) => (
                <ListItem key={value.id} dense>
                  <Checkbox
                    edge="start"
                    checked={filters[filterGroup.field]?.includes(value.id) || false}
                    onChange={(e) => {
                      const currentValues = filters[filterGroup.field] || [];
                      const newValues = e.target.checked
                        ? [...currentValues, value.id]
                        : currentValues.filter(v => v !== value.id);
                      handleFilterChange(filterGroup.field, newValues);
                    }}
                  />
                  <ListItemText 
                    primary={value.name}
                    secondary={`(${value.count})`}
                  />
                </ListItem>
              ))}
            </div>
          );
        })}
      </List>
    );
  };

  const cart = getCart();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <Typography color="error">Error loading listings: {error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Header onNavigate={handleNavigate} />
      <Container maxWidth="xl" sx={{ py: 4 }}>
        {isMobile && (
          <Box sx={{ mb: 2 }}>
            <IconButton onClick={() => setDrawerOpen(true)} color="primary">
              <FilterListIcon />
            </IconButton>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, position: 'sticky', top: 0, zIndex: 1000, p: 2, bgcolor: 'background.paper' }}>
          <IconButton 
            color="primary" 
            onClick={() => setIsCartOpen(true)}
            sx={{ 
              boxShadow: 3,
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'primary.light',
              }
            }}
          >
            <Badge badgeContent={cart.productQty} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Box>

        {renderBreadcrumbs()}

        <Grid container spacing={3}>
          {!isMobile && (
            <Grid item xs={12} md={3}>
              <Box sx={{ position: 'sticky', top: 20 }}>
                {renderFilters()}
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              {data?.items?.map((item) => (
                <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
                  <Card 
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 2,
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                    onClick={() => handleProductClick(item.id)}
                  >
                    {item.image && (
                      <CardMedia
                        component="img"
                        height="140"
                        image={item.image}
                        alt={item.name}
                        sx={{ objectFit: 'contain', mb: 2 }}
                      />
                    )}
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="h6" color="primary" gutterBottom>
                        US$ {item.price.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.size} ml
                      </Typography>
                      <Typography variant="subtitle1" component="h2" noWrap>
                        {item.name}
                      </Typography>
                    </CardContent>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<ShoppingCartIcon />}
                      sx={{ mt: 2 }}
                    >
                      Agregar
                    </Button>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ 
              mt: 4, 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2
            }}>
              <Pagination
                count={data?.pagination?.total_pages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                size="large"
              />
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1 
              }}>
                <Typography variant="body2">Ir a página:</Typography>
                <TextField
                  size="small"
                  value={goToPage}
                  onChange={handleGoToPageChange}
                  onKeyPress={handleGoToPageKeyPress}
                  sx={{ 
                    width: '70px',
                    '& input': { textAlign: 'center' }
                  }}
                  inputProps={{
                    'aria-label': 'Ir a página',
                    min: 1,
                    max: data?.pagination?.total_pages
                  }}
                />
                <Typography variant="body2">de {data?.pagination?.total_pages}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box sx={{ width: 250, p: 2 }}>
            {renderFilters()}
          </Box>
        </Drawer>

        <CartDrawer 
          open={isCartOpen}
          onClose={() => setIsCartOpen(false)}
        />

        {selectedProductId && (
          <ProductDetail
            productId={selectedProductId}
            open={!!selectedProductId}
            onClose={() => {
              console.log('Closing product detail');
              setSelectedProductId(null);
            }}
          />
        )}
      </Container>
    </Box>
  );
};

export default Listings;

const CART_KEY = 'shopping_cart';

const getDefaultCart = () => ({
  productQty: 0,
  currency: 'US$',
  promocodes: [],
  bundles: [],
  exchanges: { 'US$': 0 },
  total: 0,
  subtotal: 0,
  shipping_cost: null,
  shipping_cost_taxes: null,
  discount_subtotal: 0,
  taxes: {
    ITBMS: {
      total: 0,
      details: {
        'ITBMS (10.0%)': 0
      }
    }
  },
  plugins: { adultcontent: false },
  discount: 0,
  discounts: [],
  extras: {},
  repo: []
});

const calculateTaxes = (subtotal) => {
  const ITBMS_RATE = 0.10; // 10%
  const taxAmount = subtotal * ITBMS_RATE;
  return {
    ITBMS: {
      total: Number(taxAmount.toFixed(2)),
      details: {
        'ITBMS (10.0%)': Number(taxAmount.toFixed(2))
      }
    }
  };
};

const calculateTotals = (items) => {
  const subtotal = items.reduce((sum, item) => sum + item.calculatedPrice, 0);
  const taxes = calculateTaxes(subtotal);
  const total = subtotal + taxes.ITBMS.total;

  return {
    subtotal: Number(subtotal.toFixed(2)),
    total: Number(total.toFixed(2)),
    taxes,
    discount_subtotal: subtotal,
    exchanges: { 'US$': Number(total.toFixed(2)) }
  };
};

export const getCart = () => {
  const cartJson = localStorage.getItem(CART_KEY);
  return cartJson ? JSON.parse(cartJson) : getDefaultCart();
};

export const saveCart = (cart) => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
};

export const addToCart = (product, variant, quantity = 1) => {
  const cart = getCart();
  
  // Asegurarse de que tenemos un ID válido
  const variantId = (variant?.variant_id || variant?.id || product?.id || '').toString();
  if (!variantId) {
    throw new Error('ID de producto no válido');
  }

  // Combinar datos del producto y la variante
  const variantData = variant?.variant || variant || {};
  const productData = {
    id: variantId,
    code: variantData.friendly_code || product.friendly_code || '',
    friendly_code: variantData.friendly_code || product.friendly_code || '',
    brand: variantData.brand || product.brand || {},
    content: variantData.content || product.content || '',
    name: variantData.name || product.name || '',
    price: variantData.price || product.price || 0,
    image: variantData.media?.[0]?.url || product.media?.[0]?.url,
    symbol: variantData.symbol || product.symbol || 'US$',
    stock: variantData.stock || product.stock || 0
  };
  
  const existingItemIndex = cart.repo.findIndex(item => item.id === variantId);

  if (existingItemIndex >= 0) {
    // Si el producto ya existe, actualizar cantidad
    cart.repo[existingItemIndex].quantity += quantity;
    cart.repo[existingItemIndex].calculatedPrice = cart.repo[existingItemIndex].price * cart.repo[existingItemIndex].quantity;
  } else {
    // Si es un producto nuevo, agregarlo al carrito
    const newItem = {
      id: productData.id,
      code: productData.code,
      friendly_code: productData.friendly_code,
      brand: productData.brand,
      content: productData.content,
      name: productData.name,
      price: productData.price,
      image: productData.image,
      symbol: productData.symbol,
      stock: productData.stock,
      quantity: quantity,
      calculatedPrice: productData.price * quantity,
      info: {
        product: {
          ...productData,
          quantity: quantity,
          calculatedPrice: productData.price * quantity
        }
      },
      discounts: [],
      validations: [],
      taxes: [{
        tax_name: 'ITBMS',
        tax_pct: 10,
        total_with_taxes: Number((productData.price * quantity * 1.1).toFixed(2)),
        tax_amount: Number((productData.price * quantity * 0.1).toFixed(2))
      }],
      meta: {}
    };
    cart.repo.push(newItem);
  }

  // Actualizar totales del carrito
  const totals = calculateTotals(cart.repo);
  cart.productQty = cart.repo.reduce((sum, item) => sum + item.quantity, 0);
  cart.subtotal = totals.subtotal;
  cart.total = totals.total;
  cart.taxes = totals.taxes;
  cart.discount_subtotal = totals.discount_subtotal;
  cart.exchanges = totals.exchanges;

  saveCart(cart);
  return cart;
};

export const removeFromCart = (itemId) => {
  const cart = getCart();
  cart.repo = cart.repo.filter(item => item.id !== itemId);
  
  // Recalcular totales
  const totals = calculateTotals(cart.repo);
  cart.productQty = cart.repo.reduce((sum, item) => sum + item.quantity, 0);
  cart.subtotal = totals.subtotal;
  cart.total = totals.total;
  cart.taxes = totals.taxes;
  cart.discount_subtotal = totals.discount_subtotal;
  cart.exchanges = totals.exchanges;

  saveCart(cart);
  return cart;
};

export const updateCartItemQuantity = (itemId, newQuantity) => {
  if (newQuantity < 1) return;

  const cart = getCart();
  const itemIndex = cart.repo.findIndex(item => item.id === itemId);
  
  if (itemIndex >= 0) {
    cart.repo[itemIndex].quantity = newQuantity;
    cart.repo[itemIndex].calculatedPrice = cart.repo[itemIndex].price * newQuantity;
    cart.repo[itemIndex].taxes[0].total_with_taxes = Number((cart.repo[itemIndex].calculatedPrice * 1.1).toFixed(2));
    cart.repo[itemIndex].taxes[0].tax_amount = Number((cart.repo[itemIndex].calculatedPrice * 0.1).toFixed(2));
    
    // Recalcular totales
    const totals = calculateTotals(cart.repo);
    cart.productQty = cart.repo.reduce((sum, item) => sum + item.quantity, 0);
    cart.subtotal = totals.subtotal;
    cart.total = totals.total;
    cart.taxes = totals.taxes;
    cart.discount_subtotal = totals.discount_subtotal;
    cart.exchanges = totals.exchanges;

    saveCart(cart);
  }
  
  return cart;
};

export const clearCart = () => {
  localStorage.removeItem(CART_KEY);
  return getDefaultCart();
};

import axios from 'axios';

const API_BASE_URL = 'https://pontupedido.com'; // Adjust this to your API URL

export const getListings = async ({
  page = 1,
  limit = 12,
  path,
  ...filters
} = {}) => {
  try {
    // Convert filter arrays to comma-separated strings
    const processedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.join(',');
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    // If we have a path, use it for the API call
    const endpoint = path ? path : '/api/listings/';
    
    const response = await axios.get(`${API_BASE_URL}${endpoint}`, {
      params: {
        page,
        limit,
        ...processedFilters
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching listings');
  }
};

export const getMenus = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/menus/`, {
      params: {
        w: 1,
        l: ''
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching menus');
  }
};

export const getProductDetail = async (productId) => {
  try {
    console.log('Fetching product details for ID:', productId);
    const response = await axios.get(`${API_BASE_URL}/api/products/${productId}/`);
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response || error);
    throw new Error(error.response?.data?.message || 'Error fetching product details');
  }
};

import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Breadcrumbs,
  Link,
  Snackbar,
  Alert
} from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useQuery } from 'react-query';
import { getProductDetail } from '../services/api';
import { addToCart } from '../services/cartService';

// Lazy load de componentes pesados
const ImageList = lazy(() => import('@mui/material/ImageList'));
const ImageListItem = lazy(() => import('@mui/material/ImageListItem'));

const ProductDetail = ({ productId, open, onClose, enqueueSnackbar }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const showAlert = (message, severity = 'success') => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const { data: product, isLoading } = useQuery(
    ['product', productId],
    () => getProductDetail(productId),
    {
      enabled: !!productId,
      onSuccess: (data) => {
        // Si hay opciones, seleccionar la primera por defecto
        if (data.options && data.options.length > 0) {
          const firstOption = data.options[0];
          const variant = {
            variant_id: firstOption.variant_id,
            variant: {
              ...firstOption,
              id: firstOption.variant_id,
              name: data.name,
              brand: data.brand,
              friendly_code: firstOption.friendly_code,
              media: firstOption.media || data.media,
              symbol: firstOption.symbol || data.symbol,
              price: firstOption.price,
              stock: firstOption.stock,
              sku: firstOption.sku,
              description: firstOption.description || data.description,
              category: firstOption.category || data.category,
              subcategory: firstOption.subcategory || data.subcategory
            }
          };
          
          setSelectedVariant(variant);
          
          // Establecer la imagen principal
          const primaryImage = firstOption.media?.find(img => img.primary)?.url || 
                             firstOption.media?.[0]?.url ||
                             data.media?.find(img => img.primary)?.url ||
                             data.media?.[0]?.url;
          setSelectedImage(primaryImage);
        } else {
          // Si no hay opciones, usar los datos del producto principal
          const defaultVariant = {
            id: data.id,
            variant_id: data.id,
            variant: {
              id: data.id,
              name: data.name,
              brand: data.brand,
              friendly_code: data.friendly_code,
              media: data.media,
              symbol: data.symbol || 'US$',
              price: data.price,
              stock: data.stock,
              sku: data.sku,
              description: data.description,
              category: data.category,
              subcategory: data.subcategory,
              content: data.content
            }
          };
          setSelectedVariant(defaultVariant);
          
          const primaryImage = data.media?.find(img => img.primary)?.url || 
                             data.media?.[0]?.url;
          setSelectedImage(primaryImage);
        }
      }
    }
  );

  const handleVariantChange = (event) => {
    const variantId = event.target.value;
    console.log('Changing to variant ID:', variantId);
    console.log('Product options:', product?.options);
    
    // Buscar la opción seleccionada
    const selectedOption = product?.options?.find(opt => {
      console.log('Checking option:', opt);
      return String(opt.variant_id) === String(variantId);
    });
    
    console.log('Selected option:', selectedOption);
    
    if (selectedOption && product) {
      // Obtener los datos de la variante
      const variantMedia = selectedOption.variant?.media || selectedOption.media;
      console.log('Variant media:', variantMedia);
      
      const variant = {
        variant_id: variantId,
        variant: {
          ...selectedOption.variant,
          id: variantId,
          name: product.name,
          brand: product.brand,
          friendly_code: selectedOption.variant?.friendly_code || selectedOption.friendly_code,
          media: variantMedia || product.media,
          symbol: selectedOption.variant?.symbol || selectedOption.symbol || product.symbol,
          price: selectedOption.variant?.price || selectedOption.price,
          stock: selectedOption.variant?.stock || selectedOption.stock,
          sku: selectedOption.variant?.sku || selectedOption.sku,
          description: selectedOption.variant?.description || selectedOption.description || product.description,
          category: selectedOption.variant?.category || selectedOption.category || product.category,
          subcategory: selectedOption.variant?.subcategory || selectedOption.subcategory || product.subcategory,
          content: selectedOption.content || selectedOption.text
        }
      };
      
      console.log('Setting new variant :', variant);
      setSelectedVariant(variant);

      // Actualizar la imagen principal
      const primaryImage = 
        variantMedia?.find(img => img.primary)?.url || 
        variantMedia?.[0]?.url ||
        product.media?.find(img => img.primary)?.url ||
        product.media?.[0]?.url;
      
      console.log('Setting new primary image:', primaryImage);
      setSelectedImage(primaryImage);
      setQuantity(1);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value >= 0) {
      setQuantity(value);
    }
  };

  const getMaxQuantity = () => {
    return 9; // Máximo fijo de 9 productos
  };

  const handleAddToCart = () => {
    if (selectedVariant && quantity > 0) {
      try {
        // Asegurarse de que tenemos todos los datos necesarios
        const productData = {
          id: product.id,
          name: product.name,
          brand: product.brand,
          friendly_code: product.friendly_code,
          media: product.media,
          symbol: product.symbol || 'US$',
          price: product.price,
          stock: product.stock,
          content: product.content
        };

        addToCart(productData, selectedVariant, quantity);
        showAlert('Producto agregado al carrito');
        onClose();
      } catch (error) {
        console.error('Error adding to cart:', error);
        showAlert('Error al agregar al carrito', 'error');
      }
    }
  };

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      aria-labelledby="product-detail-title"
      TransitionProps={{
        timeout: {
          enter: 300,
          exit: 200
        }
      }}
      PaperProps={{
        sx: {
          transform: 'none',
          transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          willChange: 'opacity'
        }
      }}
    >
      <DialogContent>
        {isLoading ? (
          <CircularProgress aria-label="Loading product details" />
        ) : product ? (
          <>
            <nav aria-label="breadcrumb">
              <Breadcrumbs>
                {product.breadcrumbs?.map((crumb, index) => (
                  <Link
                    key={index}
                    color="inherit"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    {crumb}
                  </Link>
                ))}
              </Breadcrumbs>
            </nav>

            <Typography variant="h1" id="product-detail-title" sx={{ mt: 2, mb: 3 }}>
              {product.name}
            </Typography>

            <Grid container spacing={4} component="main">
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {/* Imagen Principal */}
                  <Box sx={{ width: '100%', height: '500px', position: 'relative' }}>
                    <img
                      src={selectedImage}
                      alt={`Main view of ${product.name}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </Box>
                  
                  {/* Galería de imágenes */}
                  <Box role="region" aria-label="Product gallery">
                    <Typography variant="h2" sx={{ fontSize: '1.2rem', mb: 1 }}>
                      Product Gallery
                    </Typography>
                    <Suspense fallback={<CircularProgress />}>
                      <ImageList 
                        sx={{ width: '100%', height: 'auto', mt: 2 }} 
                        cols={4} 
                        rowHeight={100}
                      >
                        {product?.media?.map((item, index) => (
                          <ImageListItem 
                            key={item.url}
                            onClick={() => handleImageClick(item.url)}
                            sx={{
                              border: selectedImage === item.url ? '2px solid' : 'none',
                              cursor: 'pointer'
                            }}
                          >
                            <img
                              src={item.url}
                              alt={`View ${index + 1} of ${product.name}`}
                              loading="lazy"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                              }}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Suspense>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box component="section" aria-labelledby="product-details-heading">
                  <Typography variant="h2" id="product-details-heading">
                    Product Details
                  </Typography>
                  
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {product.description}
                  </Typography>

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Categoría: {selectedVariant?.variant?.category || product?.category}
                    </Typography>
                    {(selectedVariant?.variant?.subcategory || product?.subcategory) && (
                      <Typography variant="subtitle1" gutterBottom>
                        Subcategoría: {selectedVariant?.variant?.subcategory || product?.subcategory}
                      </Typography>
                    )}
                  </Box>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Tamaño</InputLabel>
                    <Select
                      value={selectedVariant?.variant_id || ''}
                      onChange={handleVariantChange}
                      label="Tamaño"
                    >
                      {product?.options?.map((option, index) => {
                        const optionId = option.variant_id;
                        return (
                          <MenuItem 
                            key={optionId ? `option-${optionId}` : `option-index-${index}`} 
                            value={optionId}
                          >
                            {option.content || option.text}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <Box component="section" aria-labelledby="price-heading">
                    <Typography 
                      variant="h3" 
                      id="price-heading"
                      sx={{ 
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        mb: 1
                      }}
                    >
                      {selectedVariant?.variant?.symbol || product?.symbol} {selectedVariant?.variant?.price || product?.price}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', my: 2 }}>
                    <FormControl>
                      <InputLabel>Cantidad</InputLabel>
                      <Select
                        value={quantity}
                        onChange={handleQuantityChange}
                        label="Cantidad"
                      >
                        {Array.from({ length: 9 }, (_, i) => (
                          <MenuItem key={`quantity-${i + 1}`} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Typography variant="body1">
                      Total: {selectedVariant?.variant?.symbol || product?.symbol} {((selectedVariant?.variant?.price || product?.price) * quantity).toFixed(2)}
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleAddToCart}
                    disabled={!selectedVariant}
                    aria-label={`Add ${product?.name} to cart for ${selectedVariant?.variant?.symbol || product?.symbol} ${selectedVariant?.variant?.price || product?.price}`}
                  >
                    Add to Cart
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
      </DialogContent>
      <Snackbar 
        open={alertOpen} 
        autoHideDuration={3000} 
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleAlertClose} 
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ProductDetail;

import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  ButtonGroup,
  Divider,
  Avatar,
  dl,
  dt,
  dd
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getCart, removeFromCart, updateCartItemQuantity } from '../services/cartService';

const CartDrawer = ({ open, onClose }) => {
  // Usar estado local para forzar la actualización
  const [, forceUpdate] = useState({});

  const cart = getCart();

  const handleRemoveItem = (itemId) => {
    removeFromCart(itemId);
    forceUpdate({}); // Forzar actualización del componente
  };

  const handleUpdateQuantity = (itemId, newQuantity) => {
    if (newQuantity >= 1) {
      updateCartItemQuantity(itemId, newQuantity);
      forceUpdate({}); // Forzar actualización del componente
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      aria-labelledby="cart-title"
    >
      <Box
        sx={{ width: 400, p: 2 }}
        role="complementary"
        aria-label="Shopping cart"
      >
        <Typography variant="h1" id="cart-title" sx={{ mb: 2, fontSize: '1.5rem' }}>
          Shopping Cart
        </Typography>

        {cart.repo.length === 0 ? (
          <Typography role="status" aria-live="polite">Your cart is empty</Typography>
        ) : (
          <>
            <List aria-label="Cart items">
              {cart.repo.map((item) => (
                <ListItem
                  key={item.id}
                  component="article"
                  aria-labelledby={`item-name-${item.id}`}
                  sx={{ py: 2, px: 0 }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`Image of ${item.name}`}
                      src={item.image}
                      variant="square"
                      sx={{ width: 60, height: 60 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={`item-name-${item.id}`}
                    primary={item.name}
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item.symbol} {item.calculatedPrice.toFixed(2)} x {item.quantity}
                      </Typography>
                    }
                  />
                  <ButtonGroup 
                    size="small" 
                    variant="outlined"
                    aria-label={`Quantity controls for ${item.name}`}
                  >
                    <IconButton 
                      size="small"
                      onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
                      aria-label={`Decrease quantity of ${item.name}`}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Button 
                      disabled
                      aria-label={`Current quantity of ${item.name}: ${item.quantity}`}
                    >
                      {item.quantity}
                    </Button>
                    <IconButton 
                      size="small"
                      onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
                      aria-label={`Increase quantity of ${item.name}`}
                    >
                      <AddIcon />
                    </IconButton>
                  </ButtonGroup>
                  <IconButton
                    edge="end"
                    onClick={() => handleRemoveItem(item.id)}
                    aria-label={`Remove ${item.name} from cart`}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                  </Box>
                </ListItem>
              ))}
            </List>

            <Divider sx={{ my: 2 }} />

            <Box component="section" aria-labelledby="cart-summary" sx={{ mt: 3 }}>
              <Typography variant="h2" id="cart-summary" sx={{ fontSize: '1.2rem', mb: 1 }}>
                Cart Summary
              </Typography>
              
              <dl>
                <dt>Subtotal:</dt>
                <dd>{cart.currency} {cart.subtotal.toFixed(2)}</dd>
                <dt>ITBMS (10%):</dt>
                <dd>{cart.currency} {cart.taxes.ITBMS.total.toFixed(2)}</dd>
                <dt>Total:</dt>
                <dd>{cart.currency} {cart.total.toFixed(2)}</dd>
              </dl>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                aria-label="Proceed to checkout"
                sx={{ mt: 2 }}
              >
                Proceder al Pago
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default CartDrawer;

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useQuery } from 'react-query';
import { getMenus } from '../services/api';

const Header = ({ onNavigate }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuAnchors, setMenuAnchors] = useState({});
  const [openMenuItems, setOpenMenuItems] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: menuData } = useQuery('menus', getMenus, {
    staleTime: 300000, // 5 minutes
    cacheTime: 3600000, // 1 hour
  });

  const handleMouseEnter = (event, menuId) => {
    if (!isMobile) {
      setMenuAnchors(prev => ({
        ...prev,
        [menuId]: event.currentTarget
      }));
    }
  };

  const handleMouseLeave = (menuId) => {
    if (!isMobile) {
      setMenuAnchors(prev => ({
        ...prev,
        [menuId]: null
      }));
    }
  };

  const handleMenuItemClick = (url) => {
    onNavigate(url);
    setDrawerOpen(false);
    setMenuAnchors({});
  };

  const handleDrawerItemClick = (itemId) => {
    setOpenMenuItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  const renderDrawerMenu = (items, level = 0) => {
    if (!items) return null;
    
    return (
      <List>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onClick={() => item.submenus?.length > 0 
                ? handleDrawerItemClick(item.text)
                : handleMenuItemClick(item.url)}
              sx={{ pl: level * 2 }}
            >
              <ListItemText primary={item.text} />
              {item.submenus?.length > 0 && (
                openMenuItems[item.text] ? <ExpandLess /> : <ExpandMore />
              )}
            </ListItem>
            {item.submenus?.length > 0 && (
              <Collapse in={openMenuItems[item.text]} timeout="auto" unmountOnExit>
                {renderDrawerMenu(item.submenus, level + 1)}
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" sx={{ flexGrow: 0, mr: 4 }}>
            CLKEC
          </Typography>

          {!isMobile && menuData?.shortcuts && (
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              {menuData.shortcuts.map((item, index) => (
                <Box
                  key={index}
                  onMouseEnter={(e) => handleMouseEnter(e, item.text)}
                  onMouseLeave={() => handleMouseLeave(item.text)}
                >
                  <Button
                    color="inherit"
                    onClick={() => handleMenuItemClick(item.url)}
                  >
                    {item.text}
                  </Button>
                  {item.submenus?.length > 0 && (
                    <Menu
                      anchorEl={menuAnchors[item.text]}
                      open={Boolean(menuAnchors[item.text])}
                      onClose={() => handleMouseLeave(item.text)}
                      MenuListProps={{ onMouseLeave: () => handleMouseLeave(item.text) }}
                    >
                      {item.submenus.map((submenu, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          onClick={() => handleMenuItemClick(submenu.url)}
                        >
                          {submenu.text}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{ width: 280 }}
          role="presentation"
        >
          {menuData?.main && renderDrawerMenu(menuData.main)}
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
